import { useEffect, useState } from "react";
import { Page } from "../utils/types";
import { contentService } from "../utils/contentService";
import { Spinner } from "../components/Spinner"; // Import Spinner
import { StepszFontHeader } from "../components/StepszFontHeader";

export const ContactPage = () => {
  const [pageContent, setPageContent] = useState<Page | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Added loading state

  useEffect(() => {
    setIsLoading(true); // Set loading to true when fetching starts
    contentService.getContactPageContent().then((content) => {
      setPageContent(content);
      setIsLoading(false); // Set loading to false when fetching ends
    });
  }, []);

  return (
    <div>
      {isLoading && <Spinner />} {/* Show spinner while loading */}
      {!isLoading && (
        <>
          <StepszFontHeader>{pageContent?.title}</StepszFontHeader>
          <div
            dangerouslySetInnerHTML={{ __html: pageContent?.content || "" }}
          />
        </>
      )}
    </div>
  );
};
