import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { Media, Project } from "../utils/types";
import { contentService } from "../utils/contentService";
import { Spinner } from "../components/Spinner";
import styled from "styled-components";
import { Breakpoints } from "../utils/responsive";
import { Skeleton } from "../components/Skeleton";
import Lightbox from "yet-another-react-lightbox";

const SliderGallryContainer = styled.div`
  margin-bottom: 30px;
`;

const Masonry = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 30px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${Breakpoints.TABLET}) {
    display: none;
  }
`;

const SingleColumn = styled.div`
  display: none;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${Breakpoints.TABLET}) {
    display: flex;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
`;

const Wrapper = styled.div`
  cursor: pointer;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-weight: 400;
`;

const TileImage = ({
  image,
  onClick,
}: {
  image: Media;
  onClick: () => void;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Wrapper onClick={onClick}>
      {!isLoaded && (
        <Skeleton
          width={image.media_details.sizes.full.width}
          height={image.media_details.sizes.full.height}
        />
      )}
      <StyledImage
        src={image.media_details.sizes.full.source_url}
        alt={image.media_details.sizes.full.source_url}
        srcSet={`
      ${
        image.media_details.sizes.thumbnail
          ? `${image.media_details.sizes.thumbnail.source_url} 150w,`
          : ""
      }
      ${
        image.media_details.sizes.medium
          ? `${image.media_details.sizes.medium.source_url} 300w,`
          : ""
      }
      ${
        image.media_details.sizes.medium_large
          ? `${image.media_details.sizes.medium_large.source_url} 768w,`
          : ""
      }
      ${image.media_details.sizes.full.source_url} 800w
    `}
        onLoad={() => setIsLoaded(true)}
        style={{ display: isLoaded ? "block" : "none" }}
      />
    </Wrapper>
  );
};

export const ProjectPage = () => {
  const params = useParams();
  const { slug } = params;
  const [project, setProject] = useState<Project | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lightboxSlideIndex, setLightboxSlideIndex] = useState<
    number | undefined
  >(undefined);
  const lightBoxImages = useMemo(() => {
    return project?.mosaicGallery.map(
      (image) =>
        ({
          src: image.media_details.sizes.full.source_url,
        } ?? [])
    );
  }, [project]);
  const hasSliderGallery = useMemo(() => {
    return project?.sliderGallery && project?.sliderGallery.length > 0;
  }, [project]);
  const hasMosaic = useMemo(() => {
    return project?.mosaicGallery && project?.mosaicGallery.length > 0;
  }, [project]);

  const oddImages =
    project?.mosaicGallery.filter((_, index) => index % 2 === 0) || [];
  const evenImages =
    project?.mosaicGallery.filter((_, index) => index % 2 !== 0) || [];

  useEffect(() => {
    if (slug) {
      setIsLoading(true); // Set loading to true when fetching starts
      contentService.getProject(slug).then((project) => {
        setProject(project);
        setIsLoading(false); // Set loading to false when fetching ends
      });
    }
  }, [slug]);

  return (
    <div>
      {isLoading && !project && <Spinner />} {/* Show spinner while loading */}
      {!isLoading && project && (
        <>
          <Title>{project.title}</Title>
          {hasSliderGallery && (
            <SliderGallryContainer>
              <ImageGallery
                showThumbnails={true}
                showPlayButton={false}
                autoPlay={false}
                showNav={false}
                items={project.sliderGallery.map((image) => ({
                  original: image.media_details.sizes.full.source_url,
                  thumbnail: image.media_details.sizes.thumbnail.source_url,
                }))}
              />
            </SliderGallryContainer>
          )}
          <div dangerouslySetInnerHTML={{ __html: project.content || "" }} />
          {hasMosaic && (
            <Masonry>
              <Column>
                {oddImages.map((image, index) => (
                  <TileImage
                    key={index}
                    image={image}
                    onClick={() => {
                      const indx = project?.mosaicGallery.indexOf(image);
                      setLightboxSlideIndex(indx);
                    }}
                  />
                ))}
              </Column>
              <Column>
                {evenImages.map((image, index) => (
                  <TileImage
                    key={index}
                    image={image}
                    onClick={() => {
                      const indx = project?.mosaicGallery.indexOf(image);
                      setLightboxSlideIndex(indx);
                    }}
                  />
                ))}
              </Column>
              <SingleColumn>
                {project.mosaicGallery.map((image, index) => (
                  <TileImage
                    key={index}
                    image={image}
                    onClick={() => setLightboxSlideIndex(index)}
                  />
                ))}
              </SingleColumn>
            </Masonry>
          )}
        </>
      )}
      <Lightbox
        open={typeof lightboxSlideIndex !== "undefined"}
        close={() => setLightboxSlideIndex(undefined)}
        index={lightboxSlideIndex}
        slides={lightBoxImages}
      />
    </div>
  );
};
