import { useEffect, useState } from "react";
import { Breakpoints } from "../utils/responsive";
import { Project } from "../utils/types";
import styled from "styled-components";
import { contentService } from "../utils/contentService";
import { Link } from "react-router-dom";
import { AppRoutes } from "../utils/routes";
import Lightbox from "yet-another-react-lightbox";
import { Spinner } from "../components/Spinner";
import { Skeleton } from "../components/Skeleton";

const Masonry = styled.div`
  display: flex;
  gap: 16px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${Breakpoints.TABLET}) {
    display: none;
  }
`;

const SingleColumn = styled.div`
  display: none;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${Breakpoints.TABLET}) {
    display: flex;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
`;

const Wrapper = styled.div`
  cursor: pointer;
`;

const TileImage = ({ project }: { project: Project }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      {!isLoaded && (
        <Skeleton
          width={project.mainImage.full.width}
          height={project.mainImage.full.height}
        />
      )}
      <StyledImage
        src={project.mainImage.full.source_url}
        alt={project.title}
        srcSet={`
      ${
        project.mainImage.thumbnail
          ? `${project.mainImage.thumbnail.source_url} 150w,`
          : ""
      }
      ${
        project.mainImage.medium
          ? `${project.mainImage.medium.source_url} 300w,`
          : ""
      }
      ${
        project.mainImage.medium_large
          ? `${project.mainImage.medium_large.source_url} 768w,`
          : ""
      }
      ${project.mainImage.full.source_url} 800w
    `}
        onLoad={() => setIsLoaded(true)}
      />
    </>
  );
};

const SingleTile = ({
  project,
  onClickNoDetailsProject,
}: {
  project: Project;
  onClickNoDetailsProject: (project: Project) => void;
}) => {
  return project.noDetailsPage ? (
    <Wrapper onClick={() => onClickNoDetailsProject(project)}>
      <TileImage project={project} />
    </Wrapper>
  ) : (
    <Link to={AppRoutes.PROJECT.replace(":slug", project.slug.toString())}>
      <TileImage project={project} />
    </Link>
  );
};

export const HomePage = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lightboxImages, setLightboxImages] = useState<{ src: string }[]>([]);

  const oddProjects = projects.filter((_, index) => index % 2 === 0);
  const evenProjects = projects.filter((_, index) => index % 2 !== 0);

  useEffect(() => {
    setIsLoading(true);
    contentService.getProjects().then((projectsFromApi) => {
      setProjects(projectsFromApi);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Masonry>
          <Column>
            {oddProjects.map((project, index) => (
              <SingleTile
                key={index}
                project={project}
                onClickNoDetailsProject={(project) =>
                  setLightboxImages([
                    { src: project.mainImage.full.source_url },
                  ])
                }
              />
            ))}
          </Column>
          <Column>
            {evenProjects.map((project, index) => (
              <SingleTile
                key={index}
                project={project}
                onClickNoDetailsProject={(project) =>
                  setLightboxImages([
                    { src: project.mainImage.full.source_url },
                  ])
                }
              />
            ))}
          </Column>
          <SingleColumn>
            {projects.map((project, index) => (
              <SingleTile
                key={index}
                project={project}
                onClickNoDetailsProject={(project) =>
                  setLightboxImages([
                    { src: project.mainImage.full.source_url },
                  ])
                }
              />
            ))}
          </SingleColumn>
        </Masonry>
      )}
      <Lightbox
        open={lightboxImages.length > 0}
        close={() => setLightboxImages([])}
        slides={lightboxImages}
      />
    </div>
  );
};
