import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "../utils/routes";
import { Breakpoints } from "../utils/responsive";

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 16px 0;
  gap: 16px;
  font-family: StepszSans, sans-serif;
  font-size: 40px;
  flex-wrap: wrap;
  @media (max-width: ${Breakpoints.TABLET}) {
    justify-content: center;
    gap: 0 16px;
  }
  @media (max-width: ${Breakpoints.MOBILE}) {
    max-width: 300px;
    margin: 0 auto;
  }
`;

const NavItem = styled.li`
  @media (max-width: ${Breakpoints.TABLET}) {
    line-height: 45px;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  font-weight: normal;
  &:hover {
    font-weight: normal;
  }
  &.active {
    text-decoration: underline 1.5px solid;
  }
  &:hover {
    font-weight: normal;
  }
`;

const ExternalLink = styled.a`
  font-weight: normal;
  text-decoration: none;
  color: inherit;
  &:hover {
    font-weight: normal;
  }
`;

export const Navbar = () => {
  return (
    <NavList>
      <NavItem>
        <StyledNavLink to={AppRoutes.HOME}>Home</StyledNavLink>
      </NavItem>
      <NavItem>
        <StyledNavLink to={AppRoutes.ABOUT}>About</StyledNavLink>
      </NavItem>
      <NavItem>
        <StyledNavLink to={AppRoutes.CONTACT}>Contact</StyledNavLink>
      </NavItem>
      <NavItem>
        <ExternalLink
          href={"https://etsy.com"}
          target="_blank"
          rel="noopener noreferrer"
        >
          SHOP
        </ExternalLink>
      </NavItem>
    </NavList>
  );
};
