import axios from "axios";
import { Media, Page, Project, ProjectDTO } from "./types";

const axiosInstance = axios.create({
  baseURL: "https://cms.stepollo.com/wp-json/wp/v2/",
});

class ContentService {
  async getAboutPageContent(): Promise<Page> {
    const response = await axiosInstance.get("pages/13");
    return {
      title: response.data.title.rendered,
      content: this.stripHtmlAttributes(response.data.content.rendered),
    };
  }

  async getContactPageContent(): Promise<Page> {
    const response = await axiosInstance.get("pages/64");
    return {
      title: response.data.title.rendered,
      content: this.stripHtmlAttributes(response.data.content.rendered),
    };
  }

  async getHomePageMetadata(): Promise<string> {
    const response = await axiosInstance.get("pages/66");
    return response.data.acf.meta_description;
  }

  async getMediaByIds(ids: number[]): Promise<Media[]> {
    const response = await axiosInstance.get(
      `media?include=${ids.join(",")}&per_page=100`
    );
    return response.data;
  }

  async getProjects(): Promise<Project[]> {
    const response = await axiosInstance.get(
      "project?per_page=100&orderby=menu_order&order=asc"
    );
    const projectDtos = response.data as ProjectDTO[];
    const mediaIds = this.extractMediaIds(projectDtos);
    const media = await this.getMediaByIds(mediaIds);

    return projectDtos.map((projectDto) =>
      this.mapProjectDtoToProject(projectDto, media)
    );
  }

  async getProject(slug: string): Promise<Project> {
    const response = await axiosInstance.get(`project?slug=${slug}`);
    const projectDto = response.data[0] as ProjectDTO;
    const mediaIds = this.extractMediaIds([projectDto], false);
    const media = await this.getMediaByIds(mediaIds);

    return this.mapProjectDtoToProject(projectDto, media, false);
  }

  private extractMediaIds(
    projectDtos: ProjectDTO[],
    onlyMainImage: boolean = true
  ): number[] {
    return projectDtos.flatMap((project) => {
      let ids = [project.acf.main_image];
      if (!onlyMainImage && Array.isArray(project.acf.slider_gallery)) {
        ids = [...ids, ...project.acf.slider_gallery];
      }
      if (!onlyMainImage && Array.isArray(project.acf.mosaic_gallery)) {
        ids = [...ids, ...project.acf.mosaic_gallery];
      }
      return ids;
    });
  }

  private mapProjectDtoToProject(
    projectDto: ProjectDTO,
    media: Media[],
    onlyMainImage: boolean = true
  ): Project {
    const mainImage = media.find((m) => m.id === projectDto.acf.main_image);
    const sliderGallery = onlyMainImage
      ? []
      : Array.isArray(projectDto.acf.slider_gallery)
      ? projectDto.acf.slider_gallery.map((id) => {
          const foundMedia = media.find((m) => m.id === id);
          if (!foundMedia) {
            throw new Error(`Media with id ${id} not found`);
          }
          return foundMedia;
        })
      : [];
    const mosaicGallery = onlyMainImage
      ? []
      : Array.isArray(projectDto.acf.mosaic_gallery)
      ? projectDto.acf.mosaic_gallery.map((id) => {
          const foundMedia = media.find((m) => m.id === id);
          if (!foundMedia) {
            throw new Error(`Media with id ${id} not found`);
          }
          return foundMedia;
        })
      : [];

    return {
      id: projectDto.id,
      title: projectDto.title.rendered,
      content: this.stripHtmlAttributes(projectDto.content.rendered),
      slug: projectDto.slug,
      mainImage: mainImage ? mainImage.media_details.sizes : null,
      sliderGallery,
      mosaicGallery,
      noDetailsPage: projectDto.acf.one_night_image,
    } as Project;
  }

  private stripHtmlAttributes(html: string): string {
    return html.replace(/<(\w+)(\s+[^>]*?)?>/g, (match, p1, p2) => {
      const allowedAttributes = p2
        ? p2.match(/(href|target|src|srcset|loading|alt)="[^"]*"/g)
        : [];
      const attributesString = allowedAttributes
        ? allowedAttributes.join(" ")
        : "";
      return `<${p1}${attributesString ? " " + attributesString : ""}>`;
    });
  }
}

export const contentService = new ContentService();
