import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AboutPage } from "./pages/AboutPage";
import { HomePage } from "./pages/HomePage";
import { ContactPage } from "./pages/ContactPage";
import { AppRoutes } from "./utils/routes";
import { Layout } from "./components/Layout";
import { ProjectPage } from "./pages/ProjectPage";
import { ScrollToTop } from "./components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path={AppRoutes.HOME} element={<HomePage />} />
          <Route path={AppRoutes.ABOUT} element={<AboutPage />} />
          <Route path={AppRoutes.CONTACT} element={<ContactPage />} />
          <Route path={AppRoutes.PROJECT} element={<ProjectPage />} />
          <Route path="*" element={<Navigate to={AppRoutes.HOME} />} />
        </Routes>
      </Layout>
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
